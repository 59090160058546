import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const Icon = ({ icon }) => (
  <span className={`icon icon-${icon}`} aria-hidden="true">
    <svg>
      <use xlinkHref={`#${icon}`} />
    </svg>
  </span>
);

Icon.defaultProps = { icon: 'react' };
Icon.propTypes = { icon: PropTypes.string };
export default Icon;
