module.exports = {
  siteMetadata: {
    defaultLng: 'fr',
    title: 'Hitch - Trouvez le coach sportif pour dépasser vos objectifs',
    description:
      'Choisissez un sport. Trouvez un coach. Planifiez une séance. Faites du sport avec un pro.',
    author: '@antistatique',
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-facebook-pixel`,
      options: {
        pixelId: '407121166900199',
      },
    },
    'gatsby-plugin-sass',
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/src/assets/images`,
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    {
      resolve: `gatsby-plugin-google-tagmanager`,
      options: {
        id: 'GTM-5FCJCQF',
        includeInDevelopment: false,
      },
    },
    {
      resolve: 'gatsby-plugin-svgr',
      options: {
        prettier: false,
        svgo: false,
        svgoConfig: {
          removeViewBox: false,
          cleanupIDs: false,
        },
      },
    },
    'gatsby-plugin-offline',
  ],
};
