import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { useTranslation } from 'react-i18next';

import './Link.scss';

const Link = ({ to, children, ...props }) => {
  const { i18n } = useTranslation();
  const defaultLng = i18n.options.fallbackLng[0];
  const currentLng = i18n.language || defaultLng;

  const getPath = path =>
    currentLng === defaultLng ? `${path}` : `/${currentLng}${path}`;

  return (
    <GatsbyLink to={getPath(to)} {...props}>
      {children}
    </GatsbyLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
Link.defaultProps = {};

export default Link;
