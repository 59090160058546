import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import Icons from 'components/Icon/Icons.jsx';
import Link from 'components/Link';

import './Layout.scss';

const Layout = ({ location, children }) => {
  const { t } = useTranslation();

  return (
    <div className="site-wrapper">
      <Icons />
      <div className="container">
        <Header location={location} />
        <main>{children}</main>

        <footer className="mt-5 py-4 text-muted text-xs d-sm-flex justify-content-between">
          <span>
            Copyright Dopamine Company SA / {new Date().getFullYear()} -{' '}
            <Link className="text-underscore" to="/support">
              Contact
            </Link>{' '}
            {'- '}
            <Link className="text-underscore" to="/cgu">
              Conditions générales
            </Link>{' '}
            {'- '}
            <Link className="text-underscore" to="/privacy">
              Politique de confidentialité
            </Link>
          </span>

          <br className="d-sm-none" />
          <span>
            {t('footer.credit')}&nbsp;
            <a
              href="https://antistatique.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              Antistatique
            </a>
            .
          </span>
        </footer>
      </div>
    </div>
  );
};

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
Layout.defaultProps = {};

export default Layout;
