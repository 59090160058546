import React from 'react';

import { ReactComponent as AppleIcon } from 'assets/svg/apple.svg';
import { ReactComponent as PlaystoreIcon } from 'assets/svg/playstore.svg';
import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg';

const Icons = () => (
  <div style={{ display: 'none' }}>
    <AppleIcon />
    <PlaystoreIcon />
    <CheckIcon />
    <InstagramIcon />
    <FacebookIcon />
  </div>
);

export default Icons;
