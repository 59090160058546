import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import './LangSwitcher.scss';

const LangSwitcher = ({ location }) => {
  const { i18n } = useTranslation();
  const defaultLng = i18n.options.fallbackLng[0];
  const currentLng = i18n.language || defaultLng;
  const currentPath = location.pathname.replace(`/${currentLng}/`, '/');
  const lngs = Object.keys(i18n.options.resources);

  const langPath = lng =>
    lng === defaultLng ? `${currentPath}` : `/${lng}${currentPath}`;

  return (
    <ul className="langSwitcher">
      {lngs.map(lng => (
        <li key={lng}>
          <Link
            className={`item ${lng === currentLng ? 'active' : ''}`}
            to={langPath(lng)}
          >
            {lng}
          </Link>
        </li>
      ))}
    </ul>
  );
};

LangSwitcher.propTypes = {
  location: PropTypes.object.isRequired,
};
LangSwitcher.defaultProps = {};

export default LangSwitcher;
