import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Link from 'components/Link';
import Icon from 'components/Icon';
import Switcher from 'components/LangSwitcher';
import './Header.scss';
import { ReactComponent as Logo } from 'assets/svg/hitch.svg';

const Header = ({ location }) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-lg-10 mx-auto">
        <header className="py-5 d-flex align-items-center justify-content-between">
          <Link className="brand" to="/">
            <Logo alt={t('header.site.title')} />
          </Link>

          <span
            className="badge badge-pill text-muted"
            style={{ transform: 'scale(1.1)' }}
          >
            {t('header.available.on')}
            <Icon icon="apple" /> {t('header.soon.and')}{' '}
            <Icon icon="playstore" />
          </span>

          <Switcher location={location} />
        </header>
      </div>
    </div>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};
Header.defaultProps = {};

export default Header;
