/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import socialCover from 'assets/images/social-cover.jpg';
import widetile from 'assets/favicons/widetile.png';
import smalltile from 'assets/favicons/smalltile.png';
import mediumtile from 'assets/favicons/mediumtile.png';
import largetile from 'assets/favicons/largetile.png';
import favicon from 'assets/favicons/favicon.ico';
import icon96x96 from 'assets/favicons/favicon-96x96.png';
import icon32x32 from 'assets/favicons/favicon-32x32.png';
import icon180x180 from 'assets/favicons/apple-touch-icon-180x180.png';
import icon16x16 from 'assets/favicons/favicon-16x16.png';
import icon152x152 from 'assets/favicons/apple-touch-icon-152x152.png';
import icon144x144 from 'assets/favicons/apple-touch-icon-144x144.png';
import icon120x120 from 'assets/favicons/apple-touch-icon-120x120.png';
import icon114x114 from 'assets/favicons/apple-touch-icon-114x114.png';
import icon72x72 from 'assets/favicons/apple-touch-icon-72x72.png';
import icon76x76 from 'assets/favicons/apple-touch-icon-76x76.png';
import icon60x60 from 'assets/favicons/apple-touch-icon-60x60.png';
import icon57x57 from 'assets/favicons/apple-touch-icon-57x57.png';
import icon192x192 from 'assets/favicons/android-chrome-192x192.png';

function SEO({ lang, meta, title, metaTitle, metaDescription }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  const titleFormatted = `${title} | ${metaTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: titleFormatted,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: titleFormatted,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: socialCover,
        },
        {
          property: 'msapplication-square70x70logo',
          content: smalltile,
        },
        {
          property: 'msapplication-square150x150logo',
          content: mediumtile,
        },
        {
          property: 'msapplication-wide310x150logo',
          content: widetile,
        },
        {
          property: 'msapplication-square310x310logo',
          content: largetile,
        },
      ].concat(meta)}
    >
      <link rel="shortcut icon" type="image/x-icon" href={favicon} />
      <link rel="apple-touch-icon" sizes="57x57" href={icon57x57} />
      <link rel="apple-touch-icon" sizes="60x60" href={icon60x60} />
      <link rel="apple-touch-icon" sizes="72x72" href={icon72x72} />
      <link rel="apple-touch-icon" sizes="76x76" href={icon76x76} />
      <link rel="apple-touch-icon" sizes="114x114" href={icon114x114} />
      <link rel="apple-touch-icon" sizes="120x120" href={icon120x120} />
      <link rel="apple-touch-icon" sizes="144x144" href={icon144x144} />
      <link rel="apple-touch-icon" sizes="152x152" href={icon152x152} />
      <link rel="apple-touch-icon" sizes="180x180" href={icon180x180} />
      <link rel="icon" type="image/png" href={icon16x16} sizes="16x16" />
      <link rel="icon" type="image/png" href={icon32x32} sizes="32x32" />
      <link rel="icon" type="image/png" href={icon96x96} sizes="96x96" />
      <link rel="icon" type="image/png" href={icon192x192} sizes="192x192" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
};

export default SEO;
